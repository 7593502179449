<template>
  <div>
    <v-spacer></v-spacer>
    <v-breadcrumbs>
      <router-link to="student-addressDetals">Address</router-link>
        <v-icon>mdi-forward</v-icon>
    </v-breadcrumbs>
  </div>
</template>

<script>
  export default {
    data: () => ({
      items: [
        {
          text: 'Dashboard',
          disabled: false,
          href: 'breadcrumbs_dashboard',
        },
        {
          text: 'Link 1',
          disabled: false,
          href: 'student-addressDetals',
        },
        {
          text: 'Link 2',
          disabled: true,
          href: 'student-addressDetals',
        },
      ],
    }),
  }
</script>